import useOrders from '@/api/hooks/useOrder'
import { case_search } from '@/lib/events'
import { CaseCard } from '@/pages/dashboard/case history/component/CaseCard'
// import { CaseHistoryMiniSearch } from '@/pages/dashboard/case history/constant.case-history'
import MiniSearch from 'minisearch'
import { useEffect, useState } from 'react'

const SearchResult = ({ caseHistory }: any) => {
  const CaseHistoryMiniSearch = new MiniSearch({
    fields: [
      'id',
      'caseId',
      'patientAttenderMobile',
      'patientMobile',
      'patientName',
    ],
    storeFields: [
      'id',
      'caseId',
      'patientDetails',
      'assignmentDetails',
      'overallStatus',
      'caseSource',
      'addons',
      'totalAmount',
      'patientAttendantDetails',
      'billToClient',
      'erFSR',
      'documents',
      'type',
      'createdAt',
      'waypoints',
    ],
  })

  const [searchResults, setSearchResults] = useState<any[]>(caseHistory)
  const {
    caseHistoryFilter: { searchKeyword },
  } = useOrders()

  CaseHistoryMiniSearch?.documentCount <= 0 &&
    CaseHistoryMiniSearch.addAll(caseHistory)

  useEffect(() => {
    if (searchKeyword?.length) {
      case_search({ keyword: searchKeyword })
      const result = CaseHistoryMiniSearch.search(searchKeyword, {
        prefix: true,
      })
      const _result = searchKeyword?.toLocaleLowerCase().includes('red_')
        ? result?.filter((itm) =>
            itm?.id
              ?.toLocaleLowerCase()
              ?.includes(searchKeyword?.toLocaleLowerCase())
          )
        : result
      setSearchResults(_result)
    } else {
      setSearchResults(caseHistory)
    }
  }, [searchKeyword])

  return (
    <div className='flex flex-col gap-3'>
      {searchResults?.map((itm: any) => (
        <CaseCard key={itm?.caseId} {...itm} />
      ))}
      {searchResults?.length <= 0 && (
        <div className='flex h-64 items-center justify-center'>
          <p className='text-gray-500'>No result found</p>
        </div>
      )}
    </div>
  )
}

export default SearchResult
